<template>
  <div class="recipe-detail mb-6">
    <h1>{{ article.title }}</h1>

    <ebsn-media
      :cover="true"
      width="100%"
      :target="article"
      type="Article main"
      media="large"
      :alt="article.title"
    />

    <div
      v-if="article.articleType.name == 'recipe'"
      class="d-flex flex-justify-center mt-4"
    >
      <v-chip color="primary" class="article-course"> {{ course }} </v-chip>
      <v-spacer />
      <span>
        <v-icon class="mr-1">$clock</v-icon>
        {{ $ebsn.meta(article, "articletype.recipe_prep_time") }}
        {{ $ebsn.meta(article, "articletype.recipe_prep_time_text") }}
      </span>
    </div>
    <div
      v-else-if="article.articleType.name == 'post'"
      class="d-flex align-center flex-justify-center"
    >
      <v-chip-group>
        <v-chip
          v-for="category in categories"
          :key="category.id"
          color="primary"
          class="article-course"
        >
          {{ category.name }}
        </v-chip>
      </v-chip-group>
      <v-spacer />
      <div v-if="article.pubDate" class="small--text">
        {{ $dayjs(article.pubDate, "DD/MM/YYYY").format("DD MMMM YYYY") }}
      </div>
    </div>
    <v-runtime-template v-if="article" :template="template" />
    <IngredientList
      v-if="article.articleType.name == 'recipe'"
      :key="article.articleId"
      class="mt-3"
      :ingredients="$ebsn.meta(article, 'articletype.recipe_ingredients')"
    />
  </div>
</template>
<script>
import IngredientList from "@/components/article/IngredientList.vue";
import clickHandler from "~/mixins/clickHandler";
import ProductListSlider from "../product/ProductListSlider.vue";
import VRuntimeTemplate from "v-runtime-template";

export default {
  mixins: [clickHandler],
  /* eslint-disable vue/no-unused-components */

  components: {
    IngredientList,
    VRuntimeTemplate,
    ProductListSlider
  },
  props: {
    article: { type: Object, required: true }
  },
  computed: {
    template() {
      if (this.article) {
        return `<div>${this.article.content}</div>`;
      } else {
        return null;
      }
    },
    templateName() {
      return "Static";
      // if (this.page.template && this.page.template == "page-leftsidebar.php") {
      //   return "PageLeftsidebar";
      // } else {
      //   return "Static";
      // }
    },
    course() {
      return this.article?.articleClass.find(
        ac => ac.articleClassGroup.name == "course"
      )?.name;
    },
    categories() {
      return this.article?.articleClass?.filter(
        ac => ac.articleClassGroup.name == "category"
      );
    }
  }
};
</script>
